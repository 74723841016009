import { InjectionKey } from '@vue/runtime-core'
import { ExtractPropTypes } from 'vue'

import { CHANGE_EVENT, UPDATE_MODEL_EVENT } from '@/utils/constants'
import { isBool, isNumber, isString } from '@/utils/util'


export const radioGroupProps = {
  modelValue: {
    type: [String, Boolean, Number],
    default: '',
  },
}

export type RadioGroupProps = ExtractPropTypes<typeof radioGroupProps>

export const radioGroupEmits = {
  [UPDATE_MODEL_EVENT]: (val: string | number | boolean) =>
    isString(val) || isNumber(val) || isBool(val),
  [CHANGE_EVENT]: (val: string | number | boolean) =>
    isString(val) || isNumber(val) || isBool(val),
}

export interface RadioGroupContext extends RadioGroupProps {
  changeEvent: (val: RadioGroupProps['modelValue']) => void
}

export const radioGroupKey: InjectionKey<RadioGroupContext> =
  Symbol('radioGroupKey')
