import {browserVersionNumber} from '@/utils/browser'
import {isMobileSafari} from '@/utils/platform'

// Safari 15 之后, 键盘上方还会存在一个导航栏(高50)
// 出现其他需要适配这种额外高度的, 扩展该方法
export function getExtraHeight() {
  if (!isMobileSafari()) return 0
  const version = browserVersionNumber()
  if (version >= 15) return 50
  else return 0
}